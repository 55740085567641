import { ar } from 'vuetify/lib/locale/index.mjs';

export default {
  $vuetify: { ...ar },
  NAME_AR: 'الاسم (عربي)',
  NAME_EN: 'الاسم (إنجليزي)',
  ALL: 'الكل',
  DESCRIPTION_AR: 'الوصف (عربي)',
  DESCRIPTION_EN: 'الوصف  (إنجليزي)',
  TITLE_AR: 'العنوان (عربي)',
  TITLE_EN: 'العنوان (إنجليزي)',
  '5': '5',
  '10': '10',
  '20': '20',
  '50': '50',
  '100': '100',
  All: 'الكل',
  DASHBOARD: 'لوحة البيانات',
  ABOUT_US: 'من نحن',
  OUR_ORGANIZATION: 'مؤسستنا',
  OUR_VALUES: 'قيمنا',
  OUR_GOALS: 'أهدافنا',
  ACCOUNTS: 'الحسابات',
  SYSTEM_USERS: 'مستخدمو النظام',
  TEAMS: 'فرق العمل واللجان',
  MEMBERS: 'الأعضاء',
  PARTNERS: 'شركائنا',
  ALBUMS: 'الألبومات',
  IDENTITIES: 'الهوية البصرية',
  GOVERNANCES: 'ملفات الحوكمة',
  REPORTS: 'التقارير',
  POSTS_AND_Project: 'المنشورات والمشاريع',
  POSTS: 'المنشورات',
  PROJECTS: 'أبرز المشاريع',
  CONTACT_US: 'التواصل',
  CONTACT_CHANNELS: 'قنوات التواصل',
  SUPPORT_MESSAGES: 'الدعم الفني',
  FILES: 'الملفات',

  // website
  POST_LIST: 'المقالات',
  PHOTOS_AND_FILES: 'المحتوى المرئي',
  MEDIA: 'النافذة الإعلامية',
  WEBSITE_TEAMS: 'لجان العمل',
  CONTACT_US_WEBSITE: 'تواصل معنا',
  PROJECT_AND_PRODUCT: 'المشاريع والمنتجات'
};
