<template>
  <footer>
    <div class="footer-header bg-color3">
      <div class="container">
        <div class="row py-4" data-aos="fade-up">
          <div class="col-6 px-md-5 text-center h-100">
            <img src="img/logo-white.png" alt="" class="h-100 mw-100" />
          </div>
        </div>
      </div>
    </div>
    <div class="footer-body py-4">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-4 text-center first-col">
            <div class="logo-container mb-4" data-aos="fade-up">
              <img src="img/logo-white.png" alt="logo title" class="mw-75" />
            </div>
            <div class="social-media" data-aos="fade-left">
              <a href="tel:+966508885491" target="_blank">
                <i class="fa-solid fa-phone"></i>
              </a>
              <a href="https://wa.me/+966508885491" target="_blank">
                <i class="fa-brands fa-whatsapp"></i>
              </a>
              <a target="_blank">
                <i class="fa-brands fa-twitter"></i>
              </a>
              <a target="_blank">
                <i class="fa-brands fa-instagram"></i>
              </a>
            </div>
          </div>
          <div class="col-12 col-md-4 text-right second-col mt-5 mt-md-0">
            <h5 class="text-white font-weight-bold mb-2">روابط تهمك</h5>
            <div class="border-bottom border-9 w-15 border-color1 mb-3"></div>
            <div class="row">
              <div class="col-12 col-md-6" data-aos="fade-up">
                <ul class="footer_ul_amrc m-0 font-size-14 p-0 pb-2">
                  <li>
                    <a
                      @click="
                        () => {
                          $router.push({ name: 'aboutPage' });
                        }
                      "
                    >
                      <i class="fas fa-long-arrow-alt-left"></i
                      ><span>من نحن؟</span>
                    </a>
                  </li>
                  <li>
                    <a
                      @click="
                        () => {
                          $router.push({
                            name: 'postsList',
                            params: { type: 'products' },
                          });
                        }
                      "
                    >
                      <i class="fas fa-long-arrow-alt-left"></i
                      ><span>خدماتنا</span>
                    </a>
                  </li>
                  <li>
                    <a
                      @click="
                        () => {
                          $router.push({ name: 'projectsPage' });
                        }
                      "
                    >
                      <i class="fas fa-long-arrow-alt-left"></i
                      ><span>أبرز مشاريعنا</span>
                    </a>
                  </li>
                  <li>
                    <a
                      @click="
                        () => {
                          $router.push({ name: 'partnersPage' });
                        }
                      "
                    >
                      <i class="fas fa-long-arrow-alt-left"></i
                      ><span>شركائنا</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-12 col-md-6" data-aos="fade-up">
                <ul class="m-0 font-size-14 p-0 pb-2">
                  <li>
                    <a
                      @click="
                        () => {
                          $router.push({ name: 'contactUsPage' });
                        }
                      "
                    >
                      <i class="fas fa-long-arrow-alt-left"></i
                      ><span>تواصل معنا</span>
                    </a>
                  </li>
                  <li>
                    <a
                      @click="
                        () => {
                          $router.push({ name: 'homePage' });
                        }
                      "
                    >
                      <i class="fas fa-long-arrow-alt-left"></i
                      ><span>خريطة الموقع</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4 text-right third-col mt-5 mt-md-0">
            <h5 class="text-white font-weight-bold mb-2">يسعدنا تواصلكم</h5>
            <div class="border-bottom border-9 w-15 border-color1 mb-3"></div>
            <div class="row" data-aos="fade-up">
              <div class="col-12">
                <ul class="m-0 font-size-14 p-0 pb-2">
                  <li>
                    <a href="" class="d-flex">
                      <i class="fa-solid fa-location-dot"></i>
                      <span>المملكة العربية السعودية - الدمام - حي المنار</span>
                    </a>
                  </li>
                  <li>
                    <a href="tel:+966508885491" target="_blank">
                      <i class="fa-solid fa-phone"></i>
                      <span dir="ltr">+966508885491</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://wa.me/00966508885491" target="_blank">
                      <i class="fa-brands fa-whatsapp"></i>
                      <span dir="ltr">+966508885491</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:aro.charity@al-otaishan.com.sa"
                      target="_blank"
                    >
                      <i class="fa-solid fa-envelope"></i>
                      <span>aro.charity@al-otaishan.com.sa</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-color1">
      <div class="container">
        <p class="text-center my-0 py-2">
          جميع الحقوق محفوظة لمؤسسة عبدالرحمن العطيشان وعائلته الخيرية 2023
        </p>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts"></script>
