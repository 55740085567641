import { en } from 'vuetify/lib/locale/index.mjs';

export default {
  $vuetify: { ...en },
  NAME_AR: 'Name (ar)',
  NAME_EN: 'Name (en)',
  ALL: 'All',
  DESCRIPTION_AR: 'Description (ar)',
  DESCRIPTION_EN: 'Description (en)',
  TITLE_AR: 'Title (ar)',
  TITLE_EN: 'Title (en)',
  '5': '5',
  '10': '10',
  '20': '20',
  '50': '50',
  '100': '100',
  All: 'All',
  DASHBOARD: 'Dashboard',
  ABOUT_US: 'About us',
  OUR_ORGANIZATION: 'Our Organization',
  OUR_VALUES: 'Our Values',
  OUR_GOALS: 'Our Goals',
  ACCOUNTS: 'Accounts',
  SYSTEM_USERS: 'System Users',
  TEAMS: 'Teams',
  MEMBERS: 'Members',
  PARTNERS: 'Partners',
  ALBUMS: 'Albums',
  IDENTITIES: 'Identities',
  GOVERNANCES: 'Governances',
  REPORTS: 'Reports',
  POSTS_AND_Project: 'Posts & Project',
  POSTS: 'Posts',
  PROJECTS: 'Projects',
  CONTACT_US: 'Contact Us',
  CONTACT_CHANNELS: 'Contact Channels',
  SUPPORT_MESSAGES: 'Support Messages',
  FILES: 'Files',
  
  // website
  POST_LIST: 'Posts',
  PHOTOS_AND_FILES: 'Media',
  WEBSITE_TEAMS: 'Our Teams',
  MEDIA: 'Media',
  CONTACT_US_WEBSITE: 'Contact us',
  PROJECT_AND_PRODUCT: 'Projects & Products'
};
