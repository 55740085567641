<template>
  <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="false">
    <div class="carousel-indicators">
      <button
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide-to="0"
        class="active"
        aria-current="true"
        aria-label="Slide 1"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide-to="1"
        aria-label="Slide 2"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide-to="2"
        aria-label="Slide 3"
      ></button>
    </div>
    <div class="carousel-inner w-100" data-aos="zoom-in">
      <div
        class="carousel-item w-100 h-100 active"
        style="background-image: url(img/slider01.jpg)"
      >
        <div class="carousel-caption d-flex px-4 px-md-0">
          <div>
            <h2 class="font-weight-bold mb-4 text-color4">
              الريادة في العمل الخيري
            </h2>
            <p>نتميز بجودة مشاريعنا التنموية ودعمنا لكافة أنواع المشاريع</p>
          </div>
        </div>
      </div>
      <div
        class="carousel-item w-100 h-100"
        style="background-image: url(img/slider02.jpg)"
      >
        <div class="carousel-caption d-flex px-4 px-md-0">
          <div>
            <h2 class="font-weight-bold mb-4 text-color4">
              خدمات وقفية متنوعة
            </h2>
            <p>نسعد بتقديم الاستشارات الوقفية لكافة الجمعيات الأخرى</p>
          </div>
        </div>
      </div>
      <div
        class="carousel-item w-100 h-100"
        style="background-image: url(img/slider03.jpg)"
      >
        <div class="carousel-caption d-flex px-4 px-md-0">
          <div>
            <h2 class="font-weight-bold mb-4 text-color4">
              تنوع في المشاريع
            </h2>
            <p>أعمال ومشاريع متنوعة في عدة دول</p>
          </div>
        </div>
      </div>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#carouselExampleCaptions"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-bs-target="#carouselExampleCaptions"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script setup lang="ts"></script>
