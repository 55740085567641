import { defineStore } from 'pinia';
import { parse, stringify } from 'zipson';

import env from '@/env';
import { backendApi, AxiosError } from '@/http';
import {
  OrganizationType,
  GoalType,
  ValueType,
  TeamType,
  MemberType,
  PartnerType,
  ReportType,
  IdentityType,
  GovernanceType,
  AlbumType,
  PostType,
  ProjectType,
  SupportMessageType,
  ContactChannelType,
} from '@/composables/types';

type GlobalState = {
  _organization: OrganizationType;
  _goals: GoalType[];
  _values: ValueType[];
  _teams: TeamType[];
  _members: MemberType[];
  _partners: PartnerType[];
  _reports: ReportType[];
  _identities: IdentityType[];
  _governances: GovernanceType[];
  _albums: AlbumType[];
  _posts: PostType[];
  _projects: ProjectType[];
  _supportMessages: SupportMessageType[];
  _contactChannels: ContactChannelType[];
};

export default function useGlobalStore() {
  return defineStore('global', {
    state: (): GlobalState => ({
      _organization: {} as OrganizationType,
      _goals: [],
      _values: [],
      _teams: [],
      _members: [],
      _partners: [],
      _reports: [],
      _identities: [],
      _governances: [],
      _albums: [],
      _posts: [],
      _projects: [],
      _supportMessages: [],
      _contactChannels: [],
    }),
    getters: {
      organization(state): OrganizationType {
        return state._organization;
      },
      goals(state): GoalType[] {
        return state._goals;
      },
      values(state): ValueType[] {
        return state._values;
      },
      teams(state): TeamType[] {
        return state._teams;
      },
      members(state): MemberType[] {
        return state._members;
      },
      partners(state): PartnerType[] {
        return state._partners;
      },
      reports(state): ReportType[] {
        return state._reports;
      },
      identities(state): IdentityType[] {
        return state._identities;
      },
      governances(state): GovernanceType[] {
        return state._governances;
      },
      albums(state): AlbumType[] {
        return state._albums;
      },
      posts(state): PostType[] {
        return state._posts;
      },
      projects(state): ProjectType[] {
        return state._projects;
      },
      supportMessages(state): SupportMessageType[] {
        return state._supportMessages;
      },
      contactChannels(state): ContactChannelType[] {
        return state._contactChannels;
      },
    },
    actions: {
      async setOrganization() {
        try {
          const result = await backendApi.get('organizations/1');
          this._organization = result.data.data;
        } catch (error: unknown) {
          const message =
            error instanceof AxiosError && error.response?.data.message
              ? error.response.data.message
              : 'Failed to load data!';
          console.log(message);
          this._organization = {} as OrganizationType;
        }
      },
      async setGoals(paginate: number | undefined) {
        try {
          const result = await backendApi.get(
            'goals',
            getParams(paginate, undefined)
          );
          this._goals = result.data.data;
        } catch (error: unknown) {
          const message =
            error instanceof AxiosError && error.response?.data.message
              ? error.response.data.message
              : 'Failed to load data!';
          console.log(message);
          this._goals = [];
        }
      },
      async setValues(paginate: number | undefined) {
        try {
          const result = await backendApi.get(
            'values',
            getParams(paginate, undefined)
          );
          this._values = result.data.data;
        } catch (error: unknown) {
          const message =
            error instanceof AxiosError && error.response?.data.message
              ? error.response.data.message
              : 'Failed to load data!';
          console.log(message);
          this._values = [];
        }
      },
      async setTeams(paginate: number | undefined) {
        try {
          const result = await backendApi.get(
            'teams',
            getParams(paginate, undefined)
          );
          this._teams = result.data.data;
        } catch (error: unknown) {
          const message =
            error instanceof AxiosError && error.response?.data.message
              ? error.response.data.message
              : 'Failed to load data!';
          console.log(message);
          this._teams = [];
        }
      },
      async setMembers(paginate: number | undefined) {
        try {
          const result = await backendApi.get(
            'members',
            getParams(paginate, undefined)
          );
          this._members = result.data.data;
        } catch (error: unknown) {
          const message =
            error instanceof AxiosError && error.response?.data.message
              ? error.response.data.message
              : 'Failed to load data!';
          console.log(message);
          this._members = [];
        }
      },
      async setPartners(paginate: number | undefined) {
        try {
          const result = await backendApi.get(
            'partners',
            getParams(paginate, undefined)
          );
          this._partners = result.data.data;
        } catch (error: unknown) {
          const message =
            error instanceof AxiosError && error.response?.data.message
              ? error.response.data.message
              : 'Failed to load data!';
          console.log(message);
          this._partners = [];
        }
      },
      async setReports(paginate: number | undefined) {
        try {
          const result = await backendApi.get(
            'reports',
            getParams(paginate, undefined)
          );
          this._reports = result.data.data;
        } catch (error: unknown) {
          const message =
            error instanceof AxiosError && error.response?.data.message
              ? error.response.data.message
              : 'Failed to load data!';
          console.log(message);
          this._reports = [];
        }
      },
      async setIdentities(paginate: number | undefined) {
        try {
          const result = await backendApi.get(
            'identities',
            getParams(paginate, undefined)
          );
          this._identities = result.data.data;
        } catch (error: unknown) {
          const message =
            error instanceof AxiosError && error.response?.data.message
              ? error.response.data.message
              : 'Failed to load data!';
          console.log(message);
          this._identities = [];
        }
      },
      async setGovernances(paginate: number | undefined) {
        try {
          const result = await backendApi.get(
            'governances',
            getParams(paginate, undefined)
          );
          this._governances = result.data.data;
        } catch (error: unknown) {
          const message =
            error instanceof AxiosError && error.response?.data.message
              ? error.response.data.message
              : 'Failed to load data!';
          console.log(message);
          this._governances = [];
        }
      },
      async setAlbums(paginate: number | undefined) {
        try {
          const result = await backendApi.get(
            'albums',
            getParams(paginate, undefined)
          );
          this._albums = result.data.data;
        } catch (error: unknown) {
          const message =
            error instanceof AxiosError && error.response?.data.message
              ? error.response.data.message
              : 'Failed to load data!';
          console.log(message);
          this._albums = [];
        }
      },
      async setPosts(
        paginate: number | undefined,
        type: 'news' | 'event' | 'artical' | 'product' | undefined
      ) {
        try {
          let filter: FilterType[] | undefined = undefined;
          if (type) {
            filter = [
              {
                key: 'type',
                operator: '=',
                value: type,
              } as FilterType,
            ];
          }
          const result = await backendApi.get(
            'posts',
            getParams(paginate, filter)
          );
          this._posts = result.data.data;
        } catch (error: unknown) {
          const message =
            error instanceof AxiosError && error.response?.data.message
              ? error.response.data.message
              : 'Failed to load data!';
          console.log(message);
          this._posts = [];
        }
      },
      async setProjects(paginate: number | undefined) {
        try {
          const result = await backendApi.get(
            'projects',
            getParams(paginate, undefined)
          );
          this._projects = result.data.data;
        } catch (error: unknown) {
          const message =
            error instanceof AxiosError && error.response?.data.message
              ? error.response.data.message
              : 'Failed to load data!';
          console.log(message);
          this._projects = [];
        }
      },
      async setSupportmessages(paginate: number | undefined) {
        try {
          const result = await backendApi.get(
            'support-messages',
            getParams(paginate, undefined)
          );
          this._supportMessages = result.data.data;
        } catch (error: unknown) {
          const message =
            error instanceof AxiosError && error.response?.data.message
              ? error.response.data.message
              : 'Failed to load data!';
          console.log(message);
          this._supportMessages = [];
        }
      },
      async setContactchannels(paginate: number | undefined) {
        try {
          const result = await backendApi.get(
            'contact-channels',
            getParams(paginate, undefined)
          );
          this._contactChannels = result.data.data;
        } catch (error: unknown) {
          const message =
            error instanceof AxiosError && error.response?.data.message
              ? error.response.data.message
              : 'Failed to load data!';
          console.log(message);
          this._contactChannels = [];
        }
      },
    },
    persist: {
      debug: env.mode === 'local',
      key: env.app.webstorageNamespace + '.auth.store',
      storage: window.localStorage,
      serializer: {
        deserialize: parse,
        serialize: stringify,
      },
    },
  })();
}

type FilterType = {
  key: string | null;
  operator: '=' | '!=' | '>' | '>=' | '<' | '<=' | 'like';
  value: string | number | boolean | null;
};

function getParams(
  paginate: number | undefined,
  filters: FilterType[] | undefined
) {
  return {
    params: {
      paginate: paginate != undefined ? paginate : null,
      page: paginate != undefined ? 1 : null,
      filter: filters != undefined ? filters : null,
    },
  };
}
